<template>
  <v-navigation-drawer :value="isNavDrawerShown" @input="changeNavDrawer" app>
    <v-app-bar elevation="0" color="var(--bg-color)">
      <v-spacer></v-spacer>
      <v-app-bar-title>SEA+</v-app-bar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-divider></v-divider>
    <v-list dense>
      <!-- Menu -->
      <v-list-item>
        <v-list-item-title>
          <h3>MENU</h3>
        </v-list-item-title>
      </v-list-item>
      <v-list-item link to="/">
        <v-list-item-icon>
          <v-icon>mdi-card-account-details-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>HOME</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Account -->
      <v-list-item>
        <v-list-item-title>
          <h3>ACCOUNT</h3>
        </v-list-item-title>
      </v-list-item>
      <v-list-item link to="/sign-out">
        <v-list-item-icon>
          <v-icon color="red">mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title><span class="red--text">サインアウト</span></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<style scoped>
h3 {
  margin-top: 40px;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const navDrawerStore = createNamespacedHelpers('navDrawer')

export default {
  data: () => ({
    unsubscribe: null
  }),
  computed: {
    ...navDrawerStore.mapState(['isNavDrawerShown'])
  },
  methods: {
    ...navDrawerStore.mapActions(['changeNavDrawer'])
  }
}
</script>
