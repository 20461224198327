import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'
import { browserLocale } from '@/utils'
import 'vuetify/dist/vuetify.min.css'
import en from 'vuetify/es5/locale/en'
import ja from 'vuetify/es5/locale/ja'

Vue.use(Vuetify)

export const options = {
  lang: {
    locales: { en, ja },
    current: browserLocale || 'ja'
  },
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: colors.teal,
        secondary: colors.orange
      },
      light: {
        primary: colors.shades.black,
        secondary: colors.teal
      }
    }
  }
}

export default new Vuetify(options)
