export const state = () => ({
  isNavDrawerShown: false
})

export const mutations = {
  _setIsNavDrawerShown(state, value) {
    state.isNavDrawerShown = value
  },
  _toggleNavDrawer(state) {
    state.isNavDrawerShown = !state.isNavDrawerShown
  }
}

export const actions = {
  showNavDrawer({ commit }) {
    commit('_setIsNavDrawerShown', true)
  },
  hideNavDrawer({ commit }) {
    commit('_setIsNavDrawerShown', false)
  },
  changeNavDrawer({ commit }, value) {
    commit('_setIsNavDrawerShown', value)
  },
  toggleNavDrawer({ commit }) {
    commit('_toggleNavDrawer')
  }
}
