import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { enableAppBar: false, signInRequired: true, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/HomePage.vue')
  },
  {
    path: '/select-plan',
    name: 'selectPlan',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/SelectPlan.vue')
  },
  {
    path: '/sign-in',
    name: 'signIn',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/SignIn.vue')
  },
  {
    path: '/sign-in-with-email',
    name: 'signInWithEmail',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/SignInWithEmail.vue')
  },
  {
    path: '/sign-up',
    name: 'signUp',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/SignUp.vue')
  },
  {
    path: '/sign-up-with-email',
    name: 'signUpWithEmail',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/SignUpWithEmail.vue')
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/ResetPassword.vue')
  },
  {
    path: '/reset-password-2',
    name: 'resetPassword2',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/ResetPassword2.vue')
  },
  {
    path: '/sign-out',
    name: 'signOut',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/SignOut.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    meta: { enableAppBar: false, signInRequired: true, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/MyCheckout.vue')
  },
  {
    path: '/checkout/success',
    name: 'checkoutSuccess',
    meta: { enableAppBar: false, signInRequired: true, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/MyCheckoutSuccess.vue')
  },
  {
    path: '/select-payment-method',
    name: 'selectPaymentMethod',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/SelectPaymentMethod.vue')
  },
  {
    path: '/register-shipping-info',
    name: 'registerShippingInfo',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/CreateAddress.vue')
  },
  {
    path: '/change-shipping-info',
    name: 'changeShippingInfo',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/CreateAddress.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // const { meta } = to
  // if (meta.signInRequired) {
  //   if (store.state.client.id === null) {
  //     next({ name: 'signIn', replace: true })
  //   } else {
  //     next()
  //   }
  // } else {
  next()
  // }
})

export default router
