<template>
  <v-app>
    <v-app-bar app v-if="enableAppBar" elevation="0" color="white">
      <v-app-bar-nav-icon @click="toggleNavDrawer" />
      <v-spacer></v-spacer>
      <v-app-bar-title>{{ appBarTitle }}</v-app-bar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <navigation-drawer v-if="enableNavDrawer" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import './App.css'

import NavigationDrawer from './components/NavigationDrawer'
import { createNamespacedHelpers } from 'vuex'
import { firebaseAuth, firebaseFirestore } from '@/services/firebase'

const navDrawerStore = createNamespacedHelpers('navDrawer')
const userStore = createNamespacedHelpers('user')

export default {
  name: 'App',
  components: {
    NavigationDrawer
  },
  data: () => ({
    unsubscribe: null
  }),
  computed: {
    enableAppBar() {
      return this.$route.meta.enableAppBar
    },
    appBarTitle() {
      return this.$route.meta.appBarTitle
    },
    enableNavDrawer() {
      return this.$route.meta.enableNavDrawer
    }
  },
  methods: {
    ...navDrawerStore.mapActions(['toggleNavDrawer']),
    ...userStore.mapActions(['updateUser'])
  },
  mounted() {
    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        if (this.unsubscribe) {
          this.unsubscribe()
        }

        this.unsubscribe = firebaseFirestore
          .collection('USERS')
          .doc(user.uid)
          .onSnapshot((snap) =>
            this.updateUser({
              id: snap.id,
              ...snap.data()
            })
          )
      }
    })
  }
}
</script>
