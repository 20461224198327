export const state = () => ({
  userId: null,
  user: null
})

export const mutations = {
  updateUser(state, value) {
    state.userId = value ? value.id : null
    state.user = value
  },
  signOutUser(state) {
    state.userId = null
    state.user = null
  }
}

export const actions = {
  async updateUser({ commit }, user) {
    commit('updateUser', user)
  },
  async signOutUser({ commit }) {
    commit('signOutUser')
  }
}
