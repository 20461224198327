import '@/plugins/veevalidate'
import '@/plugins/vuemeta'
import { firebase, firebaseFirestore } from '@/services/firebase'
import { initShared } from '@shared/index'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

initShared(firebaseFirestore, firebase)

Vue.config.productionTip = false

Vue.use({ vuetify })

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
